import React, {useContext} from "react";
import SEO from "../components/seo";
import {Icon} from "../components/Framework/ui/Icons/Icons";
import {evaIconsFill} from "../components/Framework/ui/Icons/iconsEvaFill";
import {Flex} from "../components/Framework/ui/Flex/Flex";
import contact from "../images/contact.jpg"
import {PageIntroSection} from "../components/Partials/PageIntro";
import {State} from "../components/State";
// import {ContactForm} from "../components/Partials/ContactForm";


const Contact = () => {
    const appState = useContext(State);
    const isMobile = appState.isMobile;
    return (
        <>
            <SEO title="Contact"/>
            <PageIntroSection label={"Contact"} bgImage={contact}/>

            <div className={"pageWrapper"}>
                {/*<ContactForm/>*/}
                <Flex className={"contact-wrapper"} vertical={isMobile}>
                    <iframe title={"map"} className={"map"} width={isMobile ? "100%" : "75%"} height="450" frameBorder="0" allowFullScreen="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3146.0572040498087!2d23.68636621532272!3d37.95245027972751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bc662c18ac89%3A0x97985ad091ec5c98!2sP.%20Tsaldari%20347%2C%20Kallithea%20176%2075!5e0!3m2!1sen!2sgr!4v1582496000226!5m2!1sen!2sgr"/>
                    <div className={"info"}>
                        <h2>Contact Info</h2>
                        <Flex alignItems={"center"} my={"md"}>
                            <Icon mr={"xs"} icon={evaIconsFill.pin}/>
                            <span>Panagi Tsaldari 347, Kallithéa, </span>
                        </Flex>
                        <Flex alignItems={"center"} mb={"md"}>
                            <Icon mr={"xs"} icon={evaIconsFill.phone}/>
                            <span>(+30) 210 9405 060</span>
                        </Flex>
                        <Flex alignItems={"center"} mb={"md"}>
                            <Icon mr={"xs"} icon={evaIconsFill.email}/>
                            <a href="mailto:hello.timecode@gmail.com">hello.timecode@gmail.com</a>
                        </Flex>
                    </div>
                </Flex>
            </div>
        </>
    )
};

export default Contact;
